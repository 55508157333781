/* card hover animation  */

.formbx_img{
    width: 40px;
    margin-bottom: 16px;    
    -webkit-transition: transform 2s ease-in;
    -moz-transition: transform 2s ease-in;
    -o-transition: transform 2s ease-in;
    transition: transform 2s ease-in;
    /* -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; */
}
.custom-control-label:hover .formbx_img{
 -moz-transform: rotate3d(0, 1, 0, 360deg);
 -webkit-transform: rotate3d(0, 1, 0, 
360deg
);
}

/* cube circle animation  */

.section-overview .cube {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #D7D4E4;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
  }
  .section-overview .cube:nth-child(2n) {
    border-color: #FFF ;
  }
  .section-overview .cube:nth-child(2) {
    animation-delay: 1s;
    left: 25vw;
    top: 40vh;
  }
  .section-overview .cube:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 50vh;
  }
  .section-overview .cube:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
  }
  .section-overview .cube:nth-child(5) {
    animation-delay: 8s;
    left: 10vw;
    top: 85vh;
  }
  .section-overview .cube:nth-child(6) {
    animation-delay: 10s;
    left: 50vw;
    top: 10vh;
  }

  /* Animate Background*/
@keyframes Gradient {
    0% {
      background-position: 0% 10%;
    }
    50% {
      background-position: 20% 10%;
    }
    100% {
      background-position: 0% 10%;
    }
  }
  @keyframes cube {
    from {
      transform: scale(0) rotate(0deg) translate(-50%, -50%);
      opacity: 0.8;
    }
    to {
      transform: scale(0.5) rotate(960deg) translate(-50%, -50%);
      opacity: 0;
    }
  }