.text-custom1{
    color: #009bb5;
  }
  .text-custom2{
    color: #af3c95;
  }
  .bg-custom1{
    background-color: #009bb5 !important;
  }
  .bg-custom2{
    background-color: #af3c95 !important;
  }
  .bg-dark2 {
    background-color: #101010;
  }
  
  .navbar {
      position: fixed;
      /* position: relative; */
      z-index: 100;
      left: 0;
      right: 0;
      top: 0;
      /* background-color: #000; */
  }
  
  /* adds some margin below the link sets  */
  .navbar .dropdown-menu div[class*="col"] {
      margin-bottom:1rem;
   }
   
   .navbar .dropdown-menu {
     border:none;
     background-color:#0060c8!important;
   }
   
   /* breakpoint and up - mega dropdown styles */
   @media screen and (min-width: 992px) {
     
     /* remove the padding from the navbar so the dropdown hover state is not broken */
   .navbar {
    padding: 0px 15px;
     /* padding-top:0px;
     padding-bottom:0px; */
   }
   
   /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
   .navbar .nav-item {
     padding:0;
     margin:0 .25rem;
   }
   
   /* makes the dropdown full width  */
   .navbar .dropdown {position:static;}
   
   .navbar .dropdown-menu {
     width:100%;
     left:0;
     right:0;
   /*  height of nav-item  */
     top:45px;
     
     display:block;
     /* visibility: hidden; */
     opacity: 0;
     transition: visibility 0s, opacity 0.3s linear;
     
   }
     
     /* shows the dropdown menu on hover */
   .navbar .dropdown:hover .dropdown-menu, .navbar .dropdown .dropdown-menu:hover {
     display:block;
     visibility: visible;
     opacity: 1;
     transition: visibility 0s, opacity 0.3s linear;
   }
     
     .navbar .dropdown-menu {
       border: 1px solid rgba(0,0,0,.15);
       background-color: #fff;
     }
   
   }
  
   .front_header .nav-link{
     color: #fff !important;
      padding: 19px 10px;
      border-bottom: 3px solid transparent;
      transition: .4s;
      font-size: 14px;
   }
   .front_header .nav-link:focus, .front_header .nav-link:hover {
      border-bottom: 3px solid #ffcf1e;
  }
   .front_header .nav-link:focus i, .front_header .nav-link:hover i {
      color: #ffcf1e !important;
      transition: .4s;
  }