@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      /* .no-scrollbar::-webkit-scrollbar {
          display: none;
      } */

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          /* -ms-overflow-style: none; 
          scrollbar-width: none;  */
      }
    }
  }

  /* sidebar css  */
  .sidebar-panel ul li a.active{ color: rgb(31 41 55); background: #FFCF1E; transition: all ease-in-out;}

  /* view proposal css */
  .viewproposal .project-name h1{ font-size: 35px; font-weight: 700;}
  .viewproposal .project-name h5{font-size: 15px; font-weight: 400;}
  .viewproposal .project-name h4{font-size: 18px; font-weight: 400;}
 
  .viewproposal .blog-desc h1, .introduction h1,.viewproposal .timeline h1, .agreement h1, .estimateandsign h1, .project-desc h1, .project-process h1, .project-specification h1, .techstack h1{white-space: nowrap; display: flex; font-size: 30px; font-weight: 700; color: #FFCF1E; margin: 30px 0;}
  .viewproposal .blog-desc h1::after, .introduction h1::after ,.agreement h1::after, .estimateandsign h1::after, .timeline h1::after, .project-desc h1::after, .project-process h1::after, .project-specification h1::after, .techstack h1::after{content: " ";border-bottom-style: solid;border-bottom-width: 4px;border-color: #FFCF1E;display: table-cell;width: 100%;}

  .viewproposal .blog-desc ul li{ padding: 10px 0; font-size: 13px; font-weight: 400;}
  .viewproposal .introduction .compdiv1{display: flex; align-items: center; font-size: 13px;}
  .viewproposal .introduction .compdiv1 div{display: flex; align-items: center; width: 50%; padding: 15px;}

  .viewproposal .introduction .comp5 h3, .project-desc h3,.agreement h3, .project-process h3,.techstack h3, .project-specification h3{margin-bottom: 12px; font-size: 18px; font-weight: 600; color: #FFCF1E;}
  .viewproposal .introduction .comp5 h3::before, .project-desc h3::before,.techstack h3::before, .project-process h3::before, .project-specification h3::before{display: inline-block; content: ""; border-top: 2.5px solid #f6c40e; width: 3rem; margin: 0px 1rem -11px 0;  transform: translateY(-1rem);}

  .viewproposal .introduction .comp5 p,.timeline p,.agreement table p, .techstack p, .project-desc p, .agreement ul li, .project-process p, .project-specification p{font-size: 13px; font-weight: 400; margin-bottom: 10px;}

  .project-process .proc_head{color: #FFCF1E; display: flex; align-items: center; font-size: 16px; font-weight: 300;}
  .project-process .proc_head::before{content: "";width: 2px;height: 2px;border: 3.2px solid #ffc107 !important;border-radius: 50%;margin: 4px 6px 0 0;}

  .project-specification .spec_ele_deta{border-top: 0.5px solid #929292 !important;border-left: 0.5px solid #929292 !important;border-right: 0.5px solid #929292 !important; font-size: 14px; }
  .project-specification .spac_user{  border-top: 0.5px solid #929292 !important;border-bottom: 0.5px solid #929292 !important; padding: 8px;margin-bottom: 0;background-color: #f9f9f9; color: #f6c40e !important; }
  .project-specification .spec_ele{padding: 15px 8px 8px; margin-bottom: 0;}
  .project-specification .spec_inn3{padding: 0 8px 10px !important; margin-bottom: 0; display: flex; align-items: top !important;border-bottom: 0.5px solid #929292 !important;}

  .techstack .deli_ul{font-size: 14px; margin-bottom: 1rem;}
  .techstack table, .techstack table td{border: 1px solid rgb(161, 161, 161);}
  .techstack table td{padding: 10px; font-size: 13px; }

  .estimateandsign table td strong{background: #fff;color: #f6c40e;}
  .estimateandsign table td{padding: 10px; font-size: 14px;}
  .estimateandsign table tr{background: #fff; border-top: 1px solid #f6c40e;}
  .agreement table td{padding: 15px; margin-top: 5px;}
  .agreement table .agreename strong{color: #f6c40e;}
  .agreement table hr{border-color: #f6c40e; margin-bottom: 12px;}

  .timeline table td{    border-left: 3px solid #f6c40e !important;border-right: unset !important;padding-left: 20px;}
  .timeline table td::before{ position: absolute;top: 16px;left: -15px;display: inline-block;border-top: 10px solid transparent;border-left: 12px solid transparent;border-right: 0 solid transparent;border-bottom: 12px solid transparent; content: " ";}
  .timeline table td::before{ position: absolute;top: 2px;left: -2px; display: inline-block; border-top: 10px solid transparent;  border-left: 12px solid #f6c40e;border-right: 0 solid #f6c40e;border-bottom: 12px solid transparent; content: " ";}
  .timeline tr td::after{position: absolute;top: 2px; left: -2px; display: inline-block;border-top: 10px solid transparent;border-left: 12px solid #f6c40e;border-right: 0 solid #f6c40e; border-bottom: 12px solid transparent; content: " ";}
  .timeline tr::after{content: ''; display: table; clear: both;}
  .timeline tr:nth-child(even) td::before{    border-left-width: 0;border-right-width: 12px; }
  .timeline tr:nth-child(even) td::after{border-left-width: 0;border-right-width: 12px;}
  .timeline h4{color: #f6c40e;}

  /* a4 page size for export pdf */
  .a4-page {width: 210mm;height: 297mm; margin: 0 auto; padding: 20px;
  }

  /* .ant-select-arrow{cursor: pointer !important;} */
  .ant-select-selector{cursor: pointer !important;}
  input{outline: 0;}